<template>
  <v-main>
    <!-- ----------------------------------------------------------------
    ---- If Using The Meta Header Bar - A Title Must Be Specified
    ---- --------------------------------------------------------------->
    <v-card v-if="title !== applicationName" class="mx-3 mt-3" :prepend-icon="props.icon" :title="props.title" color="primary"> </v-card>
    <v-card class="mt-2 mx-3 pa-5">
      <!-- ----------------------------------------------------------------
    ---- If Showing Account Alerts
    ---- --------------------------------------------------------------->
      <v-alert v-if="accountAlertEnabled" class="mb-4" :type="accountAlertType" elevation="2" :icon="accountAlertIcon"
        ><strong>{{ accountAlertMessage }}</strong></v-alert
      >
      <!-- ----------------------------------------------------------------
    ---- Main Content Layout Area
    ---- --------------------------------------------------------------->
      <NuxtPage />
    </v-card>
  </v-main>
</template>

<script setup lang="ts">
// These Props Are Passed From a Pages Meta Data
const props = defineProps({
  title: {
    type: String,
    required: false,
  },
  icon: {
    type: String,
    required: false,
  },
})
const globalStore = useGlobalsStore()
const { accountProfile, applicationName } = storeToRefs(globalStore)
const accountAlertEnabled = computed(() => accountProfile?.value.accountAlert?.enabled || false)
const accountAlertType = computed(() => accountProfile.value.accountAlert.type)
const accountAlertMessage = computed(() => accountProfile.value.accountAlert.message)
const accountAlertIcon = computed(() => {
  if (accountProfile.value.accountAlert.icon !== undefined) return accountProfile.value.accountAlert.icon
  if (accountAlertType.value === 'error') return 'mdi-alert-circle'
  if (accountAlertType.value === 'success') return 'mdi-check-circle'
  if (accountAlertType.value === 'warning') return 'mdi-alert-circle'
  if (accountAlertType.value === 'info') return 'mdi-information'
  return 'mdi-information'
})
const testRoute = () => {
  console.log('Test Route', route.meta)
  console.log('Title', title.value)
  console.log('Icon', icon.value)
}
</script>

<style scoped></style>
