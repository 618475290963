import payload_plugin_awyBkvyjYq from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.29.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_9hTM5wFcQ2 from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__ee3c3phs62usubky7czp3xk2l4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_WtarQlCCex from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.29.1_typescript@5.7.2_vite@6.0.6_@types+no_3hmvhenw4qzrqbzeuja6pt7xiy/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_1GPM6kEvSv from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__ee3c3phs62usubky7czp3xk2l4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9BGbyZ9hWW from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__ee3c3phs62usubky7czp3xk2l4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ZuH5LScKhp from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__ee3c3phs62usubky7czp3xk2l4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_zKtlySCuPM from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__ee3c3phs62usubky7czp3xk2l4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_oxtGe7ZHpB from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__ee3c3phs62usubky7czp3xk2l4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Vpp2568g7T from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.29.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/.nuxt/components.plugin.mjs";
import prefetch_client_MpYSkHW5h1 from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__ee3c3phs62usubky7czp3xk2l4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_urIquWb21X from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.29.1_typescript@5.7.2_vite@6.0.6_@types+no_3hmvhenw4qzrqbzeuja6pt7xiy/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import firebase_client_zXNDa0wxFH from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/plugins/firebase.client.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_awyBkvyjYq,
  revive_payload_client_9hTM5wFcQ2,
  vuetify_icons_WtarQlCCex,
  unhead_1GPM6kEvSv,
  router_9BGbyZ9hWW,
  payload_client_ZuH5LScKhp,
  navigation_repaint_client_zKtlySCuPM,
  chunk_reload_client_oxtGe7ZHpB,
  plugin_vue3_Vpp2568g7T,
  components_plugin_KR1HBZs4kY,
  prefetch_client_MpYSkHW5h1,
  vuetify_no_client_hints_urIquWb21X,
  firebase_client_zXNDa0wxFH,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]