import nuxtStorage from 'nuxt-storage'

export default defineNuxtRouteMiddleware((to, from) => {
  // //////////////////////////////////////////////////////////////////
  // Auth Route Middleware - Cookie Must Exist or Redirect to Login
  // //////////////////////////////////////////////////////////////////
  const authCookie = useCookie('user')
  // console.log('From Path', from)
  // console.log('To Path', to)

  if (to.name !== 'login' && !authCookie.value) {
    return navigateTo('/login')
  }

  if (to.name === 'login' && authCookie.value) {
    return navigateTo('/')
  }

  // If Valid Route - Save Last Path to Local Storage
  // localStorage.setItem('lastPath', to.path) // Without Nuxt Storage
  nuxtStorage.localStorage.setData('lastPath', to.path)
})
