<!-- -----------------------------------------------------------------------------------------------------------------------------------
---- Top App Bar
---- ---------------------------------------------------------------------------------------------------------------------------------->
<template>
  <v-app-bar elevation="1" density="compact" app>
    <v-img class="ml-3" position="left" height="40" :src="'/images/logos/AppLogoHorizontalWithSubHeader_' + theme + '.svg'"></v-img>

    <v-spacer></v-spacer>

    <v-btn :prepend-icon="theme === 'light' ? 'mdi-weather-sunny' : 'mdi-weather-night'" slim @click="toggleTheme"></v-btn>
    <!-- ----------------------------------------------------------------
    ---- Avatar
    ---- --------------------------------------------------------------->
    <div class="mr-6">
      <v-menu min-width="200px" rounded>
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props">
            <v-avatar color="primary">
              <span class="">{{ userProfile.initials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <!-- ----------------------------------------------------------------
        ---- Avatar Menu
        ---- --------------------------------------------------------------->

        <v-card>
          <v-card-text>
            <div class="mx-auto text-center">
              <v-avatar color="primary">
                <span class="">{{ userProfile.initials }}</span>
              </v-avatar>
              <h3>{{ userProfile.fullName }}</h3>
              <p class="text-caption mt-1">
                {{ userProfile.email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn variant="text" rounded> Edit Account </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn @click="logout" variant="text" rounded> Logout </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script setup lang="ts">
// //////////////////////////////////////////////////////////////////
// Toggle Light / Dark Variants
// //////////////////////////////////////////////////////////////////
const { theme, applicationName, userProfile } = storeToRefs(useGlobalsStore())
const { toggleTheme } = useGlobalsStore()
const { userLogout } = useAuthStore()

const logout = async () => {
  await userLogout()
}
</script>

<!-- <style scoped>
.v-app-bar {
  width: calc(100% - 90px) !important;
}
</style> -->
