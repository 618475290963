
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as userSetupEeNdKolHHlMeta } from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/pages/accountSetup/userSetup.vue?macro=true";
import { default as bindingsmWHzA9STy8Meta } from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/pages/bindings.vue?macro=true";
import { default as dashboard1HK5rG6MH4nMeta } from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/pages/dashboards/dashboard1.vue?macro=true";
import { default as index5hF2PqC3CpMeta } from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/pages/index.vue?macro=true";
import { default as loginKKwqhuczSLMeta } from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/pages/login.vue?macro=true";
import { default as copyFirestoreDocumentsvgL9Sdce3ZMeta } from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/pages/superAdmin/copyFirestoreDocuments.vue?macro=true";
import { default as testOLnzghOyvMMeta } from "/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/pages/test.vue?macro=true";
export default [
  {
    name: "accountSetup-userSetup",
    path: "/accountSetup/userSetup",
    meta: userSetupEeNdKolHHlMeta || {},
    component: () => import("/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/pages/accountSetup/userSetup.vue")
  },
  {
    name: "bindings",
    path: "/bindings",
    component: () => import("/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/pages/bindings.vue")
  },
  {
    name: "dashboards-dashboard1",
    path: "/dashboards/dashboard1",
    component: () => import("/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/pages/dashboards/dashboard1.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index5hF2PqC3CpMeta || {},
    component: () => import("/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/pages/login.vue")
  },
  {
    name: "superAdmin-copyFirestoreDocuments",
    path: "/superAdmin/copyFirestoreDocuments",
    meta: copyFirestoreDocumentsvgL9Sdce3ZMeta || {},
    component: () => import("/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/pages/superAdmin/copyFirestoreDocuments.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/Users/tylerdelange/Tessella/Dev/Projects/SnowFlow/app/snowflowV3/pages/test.vue")
  }
]