/// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// Globals Store - State Used Across the Entire Application
/// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const useGlobalsStore = defineStore('globals', () => {
  // //////////////////////////////////////////////////////////////////
  // System Globals
  // //////////////////////////////////////////////////////////////////
  const applicationName = ref('SnowFlow')

  // //////////////////////////////////////////////////////////////////
  // Account Profile Information
  // //////////////////////////////////////////////////////////////////
  const docStore = useDocStore()
  const { get, getDoc } = docStore

  // Note* Account and User Docs are Synced in the docStore.docs object

  const accountId = ref(null as string | null)
  const accountProfile = ref({} as any)

  const syncAccountProfile = (account: string) => {
    accountId.value = account
    // TODO: Change Sync To True
    getDoc('accountProfile', 'accounts', account, false, accountProfile)
  }

  // //////////////////////////////////////////////////////////////////
  // User Profile Information
  // //////////////////////////////////////////////////////////////////
  const userProfileDocSync = ref({} as any)

  const userProfile = computed(() => {
    const { firstName, lastName } = userProfileDocSync.value
    const fullName = firstName && lastName ? `${firstName} ${lastName}` : ''
    const initials = firstName && lastName ? `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}` : ''

    return { ...userProfileDocSync.value, fullName, initials }
  })

  const syncUserProfile = (account: string, uid: string) => {
    // TODO: Change Sync To True
    getDoc('userProfile', `accounts/${account}/users`, uid, false, userProfileDocSync)
  }

  // //////////////////////////////////////////////////////////////////
  // Application Theme
  // //////////////////////////////////////////////////////////////////
  // "theme" has a cookie for remembering the user's theme preference
  const theme = ref('light' as 'light' | 'dark' | 'system') // app.vue will check for an active cookie
  const themeCookie = useCookie('theme')

  function toggleTheme() {
    theme.value = theme.value === 'dark' ? 'light' : 'dark'
    themeCookie.value = theme.value
  }
  return { applicationName, accountId, accountProfile, userProfile, theme, toggleTheme, syncAccountProfile, syncUserProfile }
})
