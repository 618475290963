<template>
  <!-- If Logged In -->
  <div v-if="user.uid">
    <v-app :theme="theme">
      <!-- App Bar -->
      <!------------->
      <AppTopBar />

      <!-- Navigation Drawer -->
      <!----------------------->
      <AppSideNavigation />

      <!-- App Footer -->
      <!---------------->
      <AppFooterBar />

      <!-- Main Content -->
      <!------------------>
      <AppMainContent :title="title" :icon="icon" />
    </v-app>
  </div>
  <!-- Not Logged In -->
  <div v-else>
    <v-app>
      <v-layout>
        <NuxtLayout>
          <NuxtPage />
        </NuxtLayout>
      </v-layout>
    </v-app>
  </div>
</template>

<script setup>
// Global Store Properties
const router = useRouter()
const authStore = useAuthStore()
const { theme, applicationName, userProfile, isUserSetup } = storeToRefs(useGlobalsStore())
const { user } = storeToRefs(authStore)
const { restoreSession } = authStore

const themeCookie = useCookie('theme')
const authCookie = useCookie('user')

// //////////////////////////////////////////////////////////////////
// Meta Data
// Also Generates Page Title and Icon Header Content
// //////////////////////////////////////////////////////////////////
const route = useRoute()
const title = ref(applicationName.value)
const icon = ref('mdi-snowflake')
const description = ref('SnowFlow: Snowflake Automation and Management')

watch(route, () => {
  title.value = route.meta.title || applicationName.value
  icon.value = route.meta.icon || 'mdi-snowflake'
  description.value = route.meta.description || 'SnowFlow: Snowflake Automation and Management'
})

useHead({
  title,
  meta: [
    {
      name: 'description',
      content: description,
    },
  ],
})

// Check If User Has Snowflake Account - If Not Route To User Setup
watch(userProfile, () => {
  if (userProfile.value.email) {
    if (!userProfile.value.snowflakeConnections) {
      router.push({ path: '/accountSetup/userSetup' })
    } else isUserSetup.value = false
  }
})

onMounted(() => {
  // Set Default Theme If Changed And Is In Cookie
  if (themeCookie.value) {
    theme.value = themeCookie.value
  }
  // If a refresh and "user" cookie exists, set the user in the store
  if (!user.value.uid && authCookie.value && authCookie.value.uid) {
    user.value = authCookie.value
    // Restore Sync of Account and User Profiles
    restoreSession()
  }
})
</script>
