/// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// Document and DB Subscriptions (Sync) Service
/// Tracking and Utilizing Subscribed Data States
/// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const useDocStore = defineStore('docStore', () => {
  // //////////////////////////////////////////////////////////////////
  // Holds Synced Subscription Statuses
  // //////////////////////////////////////////////////////////////////
  const docs = ref<any>({})

  // //////////////////////////////////////////////////////////////////
  // Get Subscription to a Collection
  // //////////////////////////////////////////////////////////////////
  // Specify a keyName So Syncs can be tracked and unsubscribed
  // is_sync = true if keeping document in sync with firestore server
  // Specify a sync_id to only modify documents that have been changed

  const getDoc = async (keyName: string, docPath: string, docId: string, is_sync: boolean = false, storeReference: any = null) => {
    // If keyName Doesn't Exist or Doesn't Have a "data" key then create it
    const doc_keys = { data: {}, started_at: new Date(), is_sync: is_sync }
    if (!docs.value[keyName]) docs.value[keyName] = doc_keys
    // If Missing Default Properties
    docs.value[keyName] = { ...doc_keys, ...docs.value[keyName] }

    // Get Data From Subscription Service
    const unsub = useDoc(docPath, docId, is_sync, (data) => {
      docs.value[keyName].data = data
      if (storeReference) storeReference.value = data
    })

    // Write Out the "unsub" callable function key for unsubscribing
    if (is_sync) {
      if (docs.value[keyName]) {
        if (!docs.value[keyName].unsub) {
          docs.value[keyName].unsub = await unsub
        }
      } else {
        docs.value[keyName] = { unsub: await unsub, started_at: new Date(), is_sync }
      }
    }
  }

  const getCollection = async (keyName: string, collectionPath: string, whereClauses: any[] = [], is_sync: boolean = false, sync_id: string | null = null, storeReference: any = null) => {
    // If keyName Doesn't Exist or Doesn't Have a "data" key then create it
    const doc_keys = { data: [], started_at: new Date(), is_sync: is_sync }
    if (!docs.value[keyName]) docs.value[keyName] = doc_keys
    // If Missing Default Properties
    docs.value[keyName] = { ...doc_keys, ...docs.value[keyName] }

    // Get Data From Subscription Service
    const unsub = useCollection(collectionPath, whereClauses, is_sync, (data, changes) => {
      // If Not Incremental Changes
      if (!sync_id || !docs.value[keyName].data || docs.value[keyName].data.length === 0) {
        docs.value[keyName].data = data
      }
      // If Incremental Changes Using Added, Modified, and Removed
      // Helps preventing full single page reloads
      else {
        changes.forEach((change: any) => {
          const change_doc = change.doc
          // If Modifying Document to Existing Document
          if (change.type == 'modified') {
            // Get Existing Document Index
            const docIndex = docs.value[keyName].data.findIndex((doc: any) => doc[sync_id] == change_doc[sync_id])
            // Replace Existing Document with Modified Document
            if (docIndex != -1) docs.value[keyName].data[docIndex] = change_doc
            else docs.value[keyName].data.push(change_doc)
          }
          // If Adding a New Document to Collection
          if (change.type == 'added') {
            docs.value[keyName].data.push(change_doc)
          }
          // If Removing a Document from Collection
          if (change.type == 'removed') {
            const docIndex = docs.value[keyName].data.findIndex((doc: any) => doc[sync_id] == change_doc[sync_id])
            if (docIndex != -1) docs.value[keyName].data.splice(docIndex, 1)
          }
        })
      }
    })
    // Write Out the "unsub" callable function key for unsubscribing
    if (is_sync) {
      if (docs.value[keyName]) {
        if (!docs.value[keyName].unsub) {
          docs.value[keyName].unsub = await unsub
        }
      } else {
        docs.value[keyName] = { unsub: await unsub, started_at: new Date(), is_sync }
      }
    }
  }

  // Get Data from an Already Called Document
  function get(keyName: string, defaultReturnValue: [] | {} | '' = []) {
    // If Ref Object Doesn't Already Exists Then Create It So It Can Be Subscribed To
    if (!docs.value[keyName]) {
      docs.value[keyName] = { data: defaultReturnValue }
    }
    if (!docs.value[keyName].data) {
      docs.value[keyName].data = defaultReturnValue
    }
    return computed(() => docs.value[keyName].data)
  }

  // Unsubscribe from a Subscribed Collection or Document
  // Automatically Drops from Docs Unless "drop" is set to false
  function unsub(keyName: string, drop: boolean = true) {
    if (!docs.value[keyName]) {
      return 'No Subscription Found'
    }
    if (!docs.value[keyName].unsub) {
      if (drop) delete docs.value[keyName]
      return 'Not Currently Subscribed'
    } else {
      docs.value[keyName].unsub()
      if (drop) delete docs.value[keyName]
      else docs.value[keyName].is_sync = false
      return 'Unsubscribed'
    }
  }

  return { docs, get, getDoc, getCollection, unsub }
})
