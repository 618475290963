/// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// Authentication Related States
/// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Import Firebase Types
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from 'firebase/auth'
import nuxtStorage from 'nuxt-storage'

export const useAuthStore = defineStore('auth', () => {
  // //////////////////////////////////////////////////////////////////
  // Auth Properties
  // //////////////////////////////////////////////////////////////////
  const router = useRouter()
  const { syncAccountProfile, syncUserProfile } = useGlobalsStore()

  // Auth Cookie for Keeping Login State
  const keep_alive_minutes = 180
  const authCookie = useCookie('user', { maxAge: keep_alive_minutes * 60 })

  // User Object
  const user = ref({} as any)

  // Restore Previous Session From Session Cookie
  const restoreSession = async () => {
    // Subscribe to the Account and User Profile Docs From Firestore
    syncAccountProfile(user.value.displayName)
    syncUserProfile(user.value.displayName, user.value.uid)

    // Reroute to Last Page From Local Storage - If Exists
    // const lastPath = localStorage.getItem('lastPath')
    const lastPath = nuxtStorage.localStorage.setData('lastPath')
    if (lastPath) {
      router.push({ path: lastPath })
    } else {
      router.push({ path: '/' })
    }
  }

  // Create User
  const createUser = async (email: string, password: string) => {
    const auth = getAuth()
    try {
      const userCreation = await createUserWithEmailAndPassword(auth, email, password)
      return userCreation
    } catch (error: any) {
      const errorCode = error.code
      const errorMessage = error.message
      return `Failed Creating User: ${email} | ${errorCode} ${errorMessage}`
    }
  }

  // User Login
  const userLogin = async (email: string, password: string): Promise<any | string> => {
    const auth = getAuth()
    try {
      const loginUser = await signInWithEmailAndPassword(auth, email, password)

      // If Successful Login, Set User In Auth Store
      // Note that the field "displayName" holds the users account ID
      user.value = loginUser.user
      // TODO: Temp Measure To Change AccountId: 1 => AccountId: 1712000000000
      if (loginUser.user.displayName === '1') {
        user.value.displayName = '1712000000000'
      }
      authCookie.value = JSON.stringify(user.value)
      console.log('User Successfully Logged In', user.value)

      // Subscribe to the Account and User Profile Docs From Firestore
      syncAccountProfile(user.value.displayName)
      syncUserProfile(user.value.displayName, user.value.uid)

      // Reroute to Main Page
      router.push({ path: '/' })
      return loginUser.user
    } catch (error: any) {
      const errorCode = error.code
      const errorMessage = error.message
      return `Failed To Login In User: ${email} | ${errorCode} ${errorMessage}`
    }
  }

  // User Logout
  const userLogout = async () => {
    const auth = getAuth()
    try {
      const logoutUser = await auth.signOut()
      // Clear User Store
      user.value = {}
      authCookie.value = null
      // Reroute to Login Page
      router.push({ path: '/login' })
      return
    } catch (error: any) {
      const errorCode = error.code
      const errorMessage = error.message
      return `Failed To Logout User | ${errorCode} ${errorMessage}`
    }
  }

  // Update User Firebase Auth Profile
  const updateFirebaseUser = async (payload: UpdateFirebaseUser) => {
    const auth = getAuth()
    try {
      if (auth.currentUser) {
        const updateUser = await updateProfile(auth.currentUser, payload)
        console.log('User Was Updated')
      } else {
        throw new Error('No authenticated user found')
      }
      return
    } catch (error: any) {
      const errorCode = error.code
      const errorMessage = error.message
      return `Failed To Update User | ${errorCode} ${errorMessage}`
    }
  }

  return { user, restoreSession, createUser, userLogin, userLogout, updateFirebaseUser }
})
