/// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// useDoc Composable - Gets a Firestore Collection
/// sync = true: Realtime Updates
/// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// //////////////////////////////////////////////////////////////////
// Example Usage:
// const unsub = await useDoc('accounts', '1712000000000', true, (data) => {
//     console.log('Data', data)
//   }
// )
// //////////////////////////////////////////////////////////////////

import type { Firestore } from 'firebase/firestore'
import { doc, getDoc, onSnapshot } from 'firebase/firestore'

export default async function (docPath: string = '', docId: string = '', is_sync: boolean = false, callback: (data: any) => void) {
  const { $firestore } = useNuxtApp()

  // If "sync" with listener for Realtime Updates
  if (is_sync == true) {
    return onSnapshot(
      doc($firestore as Firestore, docPath, docId),
      (docSnapshot) => {
        callback(docSnapshot.data())
      },
      (error) => {
        console.error(`Error while trying to sync collection: ${docPath}`, error)
      }
    )
    // Otherwise Get a Single Time Pull Document
  } else {
    const docSnapshot = await getDoc(doc($firestore as Firestore, docPath, docId))
    callback(docSnapshot.data())
    return docSnapshot.data()
  }
}
