<!-- -----------------------------------------------------------------------------------------------------------------------------------
---- Footer App Bar
---- ---------------------------------------------------------------------------------------------------------------------------------->

<template>
  <v-footer class="px-0 d-flex flex-column" app>
    <div class="px-4 py-2 bg-primary text-center w-100">
      © {{ new Date().getFullYear() }} —
      <strong>{{ applicationName }}</strong>
    </div>
  </v-footer>
</template>

<script setup lang="ts">
// //////////////////////////////////////////////////////////////////
// Toggle Light / Dark Variants
// //////////////////////////////////////////////////////////////////
const { applicationName } = storeToRefs(useGlobalsStore())
</script>

<style scoped></style>
