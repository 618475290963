/// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// useCollection Composable - Gets a Firestore Collection
/// sync = true: Realtime Updates
/// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Example Usage:
// const unsub = await useCollection('accounts', [['id', '==', '1712000000000']], true, (data) => {
//     console.log('Data', data)
//   }
// )
/// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import type { Firestore, QueryFieldFilterConstraint, WhereFilterOp } from 'firebase/firestore'
import { collection, query, where, getDocs, onSnapshot } from 'firebase/firestore'

export default async function (collectionPath: string = '', whereClauses: Array<Array<string | WhereFilterOp | any>> = [], sync: boolean = false, callback: (data: any, changes: any) => void) {
  const { $firestore } = useNuxtApp()

  // If Parsing Where Clauses
  const compiledWhereClauses: QueryFieldFilterConstraint[] = []
  if (whereClauses.length > 0) {
    // console.log('whereClause', whereClauses)
    whereClauses.forEach((whereClause) => {
      const whereProperty: string = whereClause[0]
      const whereOperator: WhereFilterOp = whereClause[1] as WhereFilterOp
      const whereValue: string = whereClause[2]
      compiledWhereClauses.push(where(whereProperty, whereOperator as WhereFilterOp, whereValue))
    })
  }

  // Create Query
  const q = query(collection($firestore as Firestore, collectionPath), ...compiledWhereClauses)

  // If "sync" with listener for Realtime Updates
  if (sync == true) {
    return onSnapshot(
      q,
      (querySnapshot) => {
        const collectionArray: Array<any> = []
        const changesArray: Array<any> = []
        querySnapshot.forEach((doc) => {
          collectionArray.push(formatValues(doc.data()))
        })
        querySnapshot.docChanges().forEach((change) => {
          changesArray.push({
            type: change.type,
            doc: formatValues(change.doc.data()),
          })
        })
        callback(collectionArray, changesArray)
      },
      (error) => {
        console.error(`Error while trying to sync collection: ${collectionPath}`, error)
      }
    )
    // Otherwise Get a Single Time Pull Collection
  } else {
    const docs = await getDocs(q)
    const collectionArray: Array<any> = []
    docs.forEach((doc) => {
      collectionArray.push(formatValues(doc.data()))
    })
    callback(collectionArray, [])
    return docs
  }
}

const formatValues = (docObject: any) => {
  for (const key in docObject) {
    // If Timestamp Object - Convert to JS Timestamp
    if (docObject[key] && docObject[key].nanoseconds) {
      docObject[key] = docObject[key].toDate()
    }
    // Running Status Conversions
    if (key === 'runningStatus') {
      for (const runningStatusKey in docObject['runningStatus']) {
        if (docObject[key][runningStatusKey] && docObject[key][runningStatusKey].nanoseconds) {
          docObject[key][runningStatusKey] = docObject[key][runningStatusKey].toDate()
        }
      }
    }
  }
  return docObject
}
