<!-- -----------------------------------------------------------------------------------------------------------------------------------
---- Side Navigation Bar
---- ---------------------------------------------------------------------------------------------------------------------------------->

<template>
  <!-- Main Drawer -->
  <v-navigation-drawer permanent :rail="!subDrawer" :expand-on-hover="!subDrawer" @update:rail="railState" style="position: fixed">
    <v-list density="compact" nav>
      <v-list-item v-for="mainNav in navigationSideMainItems" :key="mainNav.value" :title="mainNav.name" :prepend-icon="mainNav.icon" :value="mainNav.value" @click="subNavigation(mainNav)" />
    </v-list>
  </v-navigation-drawer>

  <!-- Sub Navigation Items -->
  <v-navigation-drawer v-model="subDrawer" temporary>
    <v-toolbar color="primary">
      <v-app-bar-nav-icon :icon="mainNavigationType.icon"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ mainNavigationType.name.toUpperCase() }}</v-toolbar-title>
    </v-toolbar>
    <v-list>
      <v-list-item v-for="subNav in navigationSubItemsList" :key="subNav.name" :title="subNav.name" :value="subNav.name" :prepend-icon="subNav.icon" @click="subNavClick(subNav.url)" />
    </v-list>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
const router = useRouter()

// Navigation Slide State
const isCollapsed = ref<boolean>(true)
const railState = (isRail: boolean) => (isCollapsed.value = isRail)
const subDrawer = ref(false)

// Navigation Items
// Navigation Main Items
import navigationSideMainItems from './navigationSideMainItems.json'

// Navigation Sub Items
import _navigationSubItems from './navigationSideSubItems.json'
const navigationSubItems: any = _navigationSubItems

const mainNavigationType = ref<any>(navigationSideMainItems[0])
const navigationSubItemsList = computed(() => navigationSubItems[mainNavigationType.value.value])

// Controls if Opening a Sub Navigation Menu or Navigating Directly to New Page
function subNavigation(mainNav: any) {
  // If url exists on mainNav - Navigate
  if (mainNav.url) {
    router.push(mainNav.url)
  } else {
    mainNavigationType.value = mainNav
    subDrawer.value = true
  }
}

function subNavClick(subNavUrl: any) {
  router.push(subNavUrl)
  subDrawer.value = false
  isCollapsed.value = true
}
</script>

<style scoped></style>
